import { ModalProvider } from "./components/context/ModalContext";
import { MixProvider } from "./components/context/MixContext";
import { DataProvider } from "./components/context/DataContext";
import { ProgressProvider } from "./components/context/ProgressContext";
import { ScreenProvider } from "./components/context/ScreenContext";
import { ModuleProvider } from "./components/context/ModuleContext";
import PopupModal from "./components/PopupModal/PopupModal";


import { useScreenShow, useScreenShowUpdate } from "./components/context/ScreenContext";

import { useModal, useModalUpdate, useModalContentUpdate } from "./components/context/ModalContext";







import { LocaleProvider } from "./components/context/LocalContext";

import './assets/webFonts/GuerbetEluciremQuiz.css'
import './App.css';

import Content from './layout/Content/Content';


function App() {


      // Screen show
      const getScreenShow = useScreenShow();
      const setScreenShow = useScreenShowUpdate();

  const setModalState = useModalUpdate();

  // const onPresenceChange = (presence) => {
  //   // Handle state changes in one function
  //   console.log(presence);
  // }


  // const onPrompt = () => {
  //   // Fire a Modal Prompt
  //   console.log('onPrompt');
  // }

  // const onIdle = (onIdle) => {
  //   // Close Modal Prompt
  //   // Do some idle action like log out your user
  //   console.log('onIdle');
  //   setScreenShow(true);

  // }

  // const onActive = (event) => {
  //   // Close Modal Prompt
  //   // Do some active action
  //   console.log('onActive');
    
  // }

  // const onAction = (event) => {
  //   // Do something when a user triggers a watched event
  //   console.log('onAction');
  // }






  return (
    <>
      <ScreenProvider>
        <LocaleProvider>
          <DataProvider>
              <ProgressProvider>
              
                  <MixProvider>
                    <ModuleProvider>
                      <ModalProvider>
                        <Content />
                        <PopupModal />
                      </ModalProvider>
                    </ModuleProvider>
                  </MixProvider>
                  {/* </IdleTimerProvider> */}
              </ProgressProvider>
          </DataProvider>
        </LocaleProvider>
      </ScreenProvider>
    </>
  );
}

export default App;
