import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import { useModal, useModalUpdate, useModalContent }  from "../context/ModalContext";
// import { useModalScroll, useModalContent }  from "../context/MixContext";
// import { useData } from "../../components/context/DataContext";

import { renderFormattedText } from "../../utils/helpers";

import './PopupModal.css'
import ModalContent from '../ModalContent/ModalContent';

const PopupModal = () => {

    const modalState = useModal(true);
    const setModalState = useModalUpdate();
    const ModalItems = useModalContent();
    const [isVisible, setIsVisible] = useState(false);

    Modal.setAppElement('#root')

    const customStyles = {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 40,
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          zIndex: 1000
        },
        content : {
          position: 'relative',
          background: '#FFFFFF',
          inset: 0,
          margin: 20,
          overflow: 'unset',
          border: "none",
          borderTopRightRadius: 4
        }
      };


      function closeModal(){
        setModalState(false);
        setIsVisible(false);
      }

      function afterOpenModal(){
        // 
      }

      function afterClose(){
        // 
      }

    return (
      <Modal
            closeTimeoutMS={0}
            isOpen={ modalState }
            onAfterOpen={afterOpenModal}
            onAfterClose={afterClose}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Quiz Modal"
            id="react-modal"
            >               
                <ModalContent close={closeModal} />     
        </Modal>
    );
};


export default PopupModal;