import React, { useEffect } from 'react';
import { useData } from "../../components/context/DataContext";
import { useModalContent } from "../../components/context/ModalContext";
import { useModal, useModalUpdate, useModalContentUpdate } from "../../components/context/ModalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { useProgress, useProgressUpdate, useScore, useScoreUpdate } from "../../components/context/ProgressContext";

import './ModalContent.css'

const ModalContent = (props) => {

    const _mdlIndex = useModalContent();
    const json = useData();
    const popupModalSet = useModalUpdate(true);

    const confirm = (e) => {
        if (e.target.innerText.toLowerCase() === "yes") {
            resetClick();
        } else {
            popupModalSet(false);
        }
    }

    // Progress
    const getProgress = useProgress();
    const setProgressState = useProgressUpdate();

    // Score
    const getScore = useScore();
    const setScore = useScoreUpdate();

    useEffect(() => {
        const modalID = document.getElementById("react-modal");
        if ( _mdlIndex === 3) {
            modalID.style.maxWidth = "540px";
        }
      }, []);

    const resetClick = () => {
        const tmparr = json.quiz
        const arr = [];
        const tmpScoreArr = [];
        tmparr.map((item, index) => {
            {
                let optArray = [];
                tmpScoreArr.push(0);
                item.questions.map((item, i, {length}) => {
                    if (length - 1 === i) {
                        // last one
                    } else {
                        optArray.push(null);
                    }
                });
                arr.push(optArray);
            }
        })
        setProgressState(arr);
        setScore(tmpScoreArr);
        
        const progresser = document.getElementsByClassName("progress");
        Array.prototype.forEach.call(progresser, function(progress, index) {
            progresser.item(index).style.width = "0";
        });
        popupModalSet(false);
    }




    if ( _mdlIndex === 0 ) {
        return (
            <div id="phaseiii">
                <div className="modal-header-transparent">
                <h1>Phase III PICTURE trial design in CNS MRI<sup>1</sup></h1>
                    <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
                <div id="modal-content2" className="modal-content-phase">
                    <div className="phaseiii-title">
                        <div className="left-line"></div>
                        <h2>Prospective, multicenter, randomized, double blind, controlled and crossover design vs Gadovist<sup>®</sup> (gadobutrol)</h2>
                    </div>
                    <div className="phaseiii-content">

                        <div className="phaseiii-column col0">
                            <div className="top-line"></div>
                            <h2>Primary outcome measures:</h2>
                            <ul className="mb10">
                                <li>Lesion visualization criteria for ELUCIREM™ (gadopiclenol) injection enhanced MRI compared with unenhanced MRI</li>
                                <li>Lesion visualization criteria for ELUCIREM™ compared with Gadovist. For each reader, only matching lesion between paired images of Gadovist and ELUCIREM™ were considered</li>
                            </ul>
                            <h2>Lesion visualization criteria:</h2>
                            <ul>
                                <li>Border delineation</li>
                                <li>Internal morphology</li>
                                <li>Degree of contrast enhancement</li>
                            </ul>
                        </div>
                        <div className="phaseiii-column flex-column white-bg col2">
                            <div className="line-parent">
                            <div className="phaseiii-textbox-line"></div>
                            <div className="phaseiii-textbox">
                                
                                <div className="phaseiii-textbox-text">
                                    <h2>256 patients randomized</h2>
                                    <p>Mean age: 57 years (18–84)</p>
                                    <p>Female: 53%</p>
                                </div>
                            </div>
                            </div>
                            
                            <div className="phaseiii-arrow"></div>
                            <div className="phaseiii-textbox">
                                <div className="phaseiii-textbox-text">
                                    <h2>250 received at least one injection</h2>
                                </div>
                            </div>
                            <div className="phaseiii-arrow"></div>
                            <div className="phaseiii-textbox">
                                <div className="phaseiii-textbox-text">
                                    <h2>242 patients completed the trial (received the 2 contrast agents)</h2>
                                    <p>minimum 4 days and maximum 23 days</p>
                                </div>
                            </div>
                        </div>
                        <div className="phaseiii-column col1">
                            <div className="top-line"></div>
                            <div className="left-line"></div>
                            <h2>Inclusion criteria:</h2>
                            <ul className="mb10">
                                <li>Patients presenting with known or highly suspected CNS lesion(s) with focal areas of disrupted blood-brain barrier (primary and secondary tumors)</li>
                                <li>Lesions based on results of a previous imaging procedure performed within 12 months prior to ICF signature</li>
                            </ul>
                            <h2>Exclusion criteria:</h2>
                            <ul>
                                <li>Patient presenting with acute or chronic renal insufficiency, defined as an estimated Glomerular Filtration Rate (eGFR) &lt;30 mL/min/1.73 m<sup>2</sup> assessed within 1 day prior to each contrast agent injection</li>
                                {/* <li>Internal morphology</li> */}
                                <li>Patient presenting extra cranial lesions and/or extra dural lesions</li>
                            </ul>
                        </div>
                    </div>
                    <div className="phaseiii-footnote">
                        <p><strong>CNS:</strong> central nervous system; <strong>ICF:</strong> informed consent form;<strong> MRI:</strong> magnetic resonance Imaging</p>
                        <p><strong>Reference: 1.</strong> Loevner LA, et al. Invest Radiol. 2023;58(5):307–313.</p>
                        <p><a href="https://www.elucirem-global.com/media/x41o4k41/mentions-l%C3%A9gales-abr%C3%A9g%C3%A9es-corporate-elucirem.pdf" target="_blank">Click here</a> to see ELUCIREM™ Summary of Products Characteristics (SmPC). For more information, please refer to you local SmPC.</p>
                    </div>
                    
                </div>
            </div>
        );
    }

    if ( _mdlIndex === 1 ) {
        return (
            <div id="phaseiii">
                <div className="modal-header-transparent">
                <h1>Phase III PROMISE trial design in body MRI<sup>1</sup></h1>
                    <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
                <div id="modal-content2" className="modal-content-phase">
                    <div className="phaseiii-title">
                        <div className="left-line"></div>
                        <h2>Prospective, multicenter, randomized, double blind, controlled and crossover design vs Gadovist<sup>®</sup> (gadobutrol)</h2>
                    </div>
                    <div className="phaseiii-content">

                        <div className="phaseiii-column col0">
                            <div className="top-line"></div>
                            <h2>Primary outcome measures:</h2>
                            <ul className="mb10">
                                <li>Lesion visualization criteria for ELUCIREM™ (gadopiclenol) injection enhanced MRI compared with unenhanced MRI</li>
                                <li>Lesion visualization criteria for ELUCIREM™ compared with Gadovist. For each reader, only matching lesion between paired and ELUCIREM™ were considered</li>
                            </ul>
                            <h2>Lesion visualization criteria:</h2>
                            <ul>
                                <li>Border delineation</li>
                                <li>Internal morphology</li>
                                <li>Degree of contrast enhancement</li>
                            </ul>
                        </div>
                        <div className="phaseiii-column flex-column white-bg  col2">
                            <div className="line-parent">
                            <div className="phaseiii-textbox-line"></div>
                            <div className="phaseiii-textbox">
                                
                                <div className="phaseiii-textbox-text">
                                    <h2>304 patients randomized</h2>
                                    <p>Mean age: 57 years (21–86)</p>
                                    <p>Female: 59%</p>
                                </div>
                            </div>
                            </div>
                            
                            <div className="phaseiii-arrow"></div>
                            <div className="phaseiii-textbox">
                                <div className="phaseiii-textbox-text">
                                    <h2>300 received at least one injection</h2>
                                </div>
                            </div>
                            <div className="phaseiii-arrow"></div>
                            <div className="phaseiii-textbox">
                                <div className="phaseiii-textbox-text">
                                    <h2>275 patients completed the trial (received the 2 contrast agents)</h2>
                                    <p>Study duration per patient:</p>
                                    <p>maximum 23 days</p> 
                                </div>
                            </div>
                        </div>
                        <div className="phaseiii-column col1">
                            <div className="top-line"></div>
                            <div className="left-line"></div>
                            <h2>Inclusion criteria:</h2>
                            <ul className="mb10">
                                <li>Presenting with known or suspected enhancing abnormality(ies) and/or lesion(s) in at least one body region<sup>*</sup></li>
                                <li>Abnormalities identified based on a previous imaging procedure performed within 12 months prior to ICF signature</li>
                            </ul>
                            <h2>Exclusion criteria:</h2>
                            <ul>
                                <li>Patient presenting with acute or chronic renal insufficiency, defined as an estimated Glomerular Filtration Rate (eGFR) &lt;30 mL/min/1.73 m<sup>2</sup> assessed within 1 day prior to each contrast agent administration</li>
                            </ul>
                        </div>
                    </div>
                    <div className="phaseiii-footnote">
                        <p><strong>GBCA:</strong> US patients were restricted to the breast in compliance with local approved indications of gadobutrol. <strong>ICF:</strong> informed consent form; <strong>MRI:</strong> magnetic resonance imaging.</p>
                        <p><sup>*</sup>US patients were restricted to the breast in compliance with local approved indications of gadobutrol.</p>
                        <p><strong>Reference: 1.</strong> Kuhl C, et al. Radiology. 2023;308(1):e222612.</p>
                        <p><a href="https://www.elucirem-global.com/media/x41o4k41/mentions-l%C3%A9gales-abr%C3%A9g%C3%A9es-corporate-elucirem.pdf" target="_blank">Click here</a> to see ELUCIREM™ Summary of Products Characteristics (SmPC). For more information, please refer to you local SmPC.</p>
                    </div>
                    
                </div>
            </div>
        );
    }

    if ( _mdlIndex === 2 ) {


        return (
            <div id="isi">
                <div className="modal-header-transparent">
                <h1 className="txt-center">&nbsp;</h1>
                    <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
               
                <div id="modal-content" className="modal-content disclaim">
                    {/* <h1 className="txt-center">Disclaimer</h1>
                    <div className="disclaimer">
                        
                        <p><strong>Please see Important Safety Information, including Full Prescribing Information and Boxed WARNING, located in Booth. Elucirem™ is approved in U.S. only and under evaluation by the European Medicines Agency. Refer to U.S. PI and the Guerbet Medical Team for more information.</strong></p>
                    </div> */}
                
                    <h1 className="txt-center">Important safety information</h1>
                    <div className="warning-box">
                    <h2 className="txt-center">WARNING: NEPHROGENIC SYSTEMIC FIBROSIS (NSF)</h2>
                    <p className="txt-center mb10"><i>See full prescribing information for complete boxed warning</i></p>
                        <p>Gadolinium based contrast agents (GBCAs) increase the risk for NSF among patients with impaired elimination of the drugs. Avoid use of GBCAs in these patients unless the diagnostic information is essential and not available with non contrasted MRI or other modalities.</p>
                        <ul>
                            <li>The risk for NSF appears highest among patients with:
                                <ul>
                                    <li>Chronic, severe kidney disease (GFR &lt;30 mL/min/1.73 m 2 ), or</li>
                                    <li>Acute kidney injury</li>
                                </ul>
                            </li>
                            <li>Screen patients for acute kidney injury and other conditions that may reduce renal function. For patients at risk for chronically reduced renal function (for example, age &gt;60 years, hypertension or diabetes), estimate the glomerular filtration rate (GFR) through laboratory testing</li>
                        </ul>
                    </div>

                    <h2 className="mb10">ELUCIREM™ gadopiclenol ) injection Important Safety Information</h2>
                    <h3>Indications and Usage</h3>
                    <p className="last">ELUCIREM™ (gadopiclenol ) injection is indicated in adult and pediatric patients aged 2 years and older for use with magnetic resonance imaging (to detect and visualize lesions with abnormal vascularity in the central nervous system (brain, spine, and associated tissues), and the body (head and neck, thorax, abdomen, pelvis, and musculoskeletal system).</p>

                    <h3>Contraindications</h3>
                    <p className="last">History of hypersensitivity reactions to ELUCIREM</p>

                    <h3>Warnings and precautions</h3>
                    <ul className="last">
                        <li><strong>Nephrogenic Systemic Fibrosis:</strong> GBCAs increase the risk for NSF among patients with impaired elimination of the drugs. Avoid use of GBCAs among these patients unless the diagnostic information is essential and not available with non contrast MRI or other modalities. The GBCA associated NSF risk appears highest for patients with chronic, severe kidney disease as well as patients with acute kidney injury.</li>
                        <li><strong>Hypersensitivity Reactions:</strong> With GBCAs, serious hypersensitivity reactions have occurred. In most cases, initial symptoms occurred within minutes of GBCA administration and resolved with prompt emergency treatment. Before ELUCIREM administration, assess all patients for any history of a reaction to contrast media, bronchial asthma and/or allergic disorders. These patients may have an increased risk for a hypersensitivity reaction to ELUCIREM.</li>
                        <li><strong>Gadolinium Retention:</strong> Gadolinium is retained for months or years in several organs. Linear GBCAs cause more retention than macrocyclic GBCAs. Consequences of gadolinium retention in the brain have not been established. Pathologic and clinical consequences of GBCA administration and retention in skin and other organs have been established in patients with impaired renal function. While clinical consequences of gadolinium retention have not been established in patients with normal renal function, certain patients might be at higher risk. These include patients requiring multiple lifetime doses, pregnant and pediatric patients, and patients with inflammatory conditions. Consider the retention characteristics of the agent when choosing a GBCA for these patients. Minimize repetitive GBCA imaging studies, particularly closely spaced studies when possible.</li>
                        <li><strong>Acute Kidney Injury:</strong> In patients with chronically reduced renal function, acute kidney injury requiring dialysis has occurred with the use of GBCAs. The risk of acute kidney injury may increase with increasing dose of the contrast agent. Do not exceed the recommended dose.</li>
                        <li><strong>Extravasation and Injection Site Reactions:</strong> Injection site reactions such as injection site pain have been reported in the clinical studies with ELUCIREM. Extravasation during ELUCIREM administration may result in tissue irritation. Ensure catheter and venous patency before the Injection of ELUCIREM.</li>
                        <li><strong>Interference with Visualization of Lesions Visible with Non Contrast MRI:</strong> As with any GBCA, ELUCIREM may impair the visualization of lesions seen on non contrast MRI. Therefore, caution should be exercised when Gadopiclenol MRI scans are interpreted without a companion non contrast MRI scan.</li>
                    </ul>

                    <h3>Adverse Reactions:</h3>
                    <p className="last">In clinical trials, the most frequent adverse reactions that occurred in > 0.2% of patients who received ELUCIREM included: Injection site pain, headache, nausea, injection site warmth, injection site coldness, dizziness, and localized swelling. Adverse reactions that occurred with a frequency 0.2% in patients who received 0.05 mmol/kg BW ELUCIREM included: aculopapular rash, vomiting, worsened renal impairment, feeling hot, pyrexia, oral paresthesia, dysgeusia, diarrhea, pruritus, allergic dermatitis, erythema, injection site paresthesia, Cystatin C increase, and blood creatinine increase.</p>

                    <h3>Use in Specific Populations</h3>
                    <ul className="last">
                        <li><strong>Pregnancy:</strong> GBCAs cross the human placenta and result in fetal exposure and gadolinium retention. There are no available data on ELUCIREM use in pregnant women to evaluate for a drug associated risk of major birth defects, miscarriage or other adverse maternal or fetal outcomes.</li>
                        <li><strong>Lactation:</strong> There are no data on the presence of ELUCIREM in human milk, the effects on the breastfed infant, or the effects on milk production. However, published lactation data on other GBCAs indicate that 0.01 to 0.04% of the maternal gadolinium dose is excreted in breast milk.</li>
                        <li><strong>Pediatric Use:</strong> The safety and effectiveness of ELUCIREM have not been established in pediatric patients younger than 2 years of age.</li>
                        <li><strong>Geriatric Use:</strong> This drug is known to be substantially excreted by the kidney, and the risk of adverse reactions to this drug may be greater in patients with impaired renal function.</li>
                        <li><strong>Renal Impairment:</strong> In patients with renal impairment, the exposure of gadopiclenol is increased compared to patients with normal renal function. This may increase the risk of adverse reactions such as nephrogenic systemic fibrosis (NSF). Avoid use of GBCAs among these patients unless the diagnostic information is essential and not available with non contrast MRI or other modalities. No dose adjustment of ELUCIREM is recommended for patients with renal impairment. ELUCIREM can be removed from the body by hemodialysis.</li>
                    </ul>
                    <div className="isi-footnote">
                        <p>You are encouraged to report negative side effects of prescription drugs to the FDA.</p>
                        <p>Visit www.fda.gov/medwatch or call 1-800-FDA-1088.</p>
                        <p>Please see the full Prescribing Information, including the Medication Guide, for additional important safety information</p>
                    </div>
                </div>
            </div>
        );
    }

    if ( _mdlIndex === 3 ) {

        return (
            <div id="confirm">
                <div className="modal-header-transparent">
                    <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
                <div id="modal-content" className="modal-content">
                    <div className="warning">
                        <div className="warning-text">
                            <h1>WARNING: This will reset all progress.</h1>
                            <p>Are you sure you want to proceed?</p>
                        </div>
                        <div className="warning-buttons">
                            <div className="btn red" onClick={confirm}>yes</div>
                            <div className="btn" onClick={confirm}>no</div>
                        </div>
                    </div>
                </div>
            </div>
        );
        
    }

    
};

export default ModalContent;