export const resetProgress = (data) => {
    
    const arr = [];
        data.map((item, index) => {
            {
                let optArray = [];
                item.questions.map((item2, index2) => {
                    if ( item2[0].instance != "result" ) {
                        optArray.push(null);
                    }
                })
                arr.push(optArray);
            }
        })
        return arr;

};
