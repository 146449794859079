import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// polyfill for IE11 for urlsearchparams
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';





// console.log(process.env.REACT_APP_LOCALE);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <App />
  // </React.StrictMode>
);
