import React, { useState } from 'react';
import VideoJS from './VideoJS';
import { useIdleTimerContext } from 'react-idle-timer';

import './Video.css';

const Player = (props) => {

    // const [foo, fooSet] = useState();
    const _idletimer = useIdleTimerContext();
    

   const playerRef = React.useRef(null);
   const videoJsOptions = {
      poster: require('../../assets/video/MOA_video_global-poster.jpg'),
      class: "video-js vjs-default-skin vjs-big-play-centered vjs-show-big-play-button-on-pause",
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
    //   datasetup: { "poster": require('../../assets/video/MOA_Elucirem_US_8feb-poster.jpg') },
      sources: [{
      src: require('../../assets/video/MOA_video_global.mp4'),
      type: 'video/mp4'
      }]
   };

   const handlePlayerReady = (player) => {
    //  playerRef.current = player;

//     //  player.pause(true);
 
    //  You can handle player events here, for example:
     player.on('waiting', () => {
    //   console.log('player is waiting');
     });
 
    //  player.on('dispose', () => {
    //   // playerRef.log('player will dispose');
    //  });

    player.on("timeupdate", function () {
        // withIdleTimer.pause()
        // // console.log(withIdleTimer);
        
    });

    player.on("pause", function () {
        // console.log("pause");
        _idletimer.start();
    });

    player.on("play", function () {
        _idletimer.pause();
    });
     
    player.on("ended", function(){
        player.bigPlayButton.show();
        player.currentTime(0);
        player.hasStarted(false);
        player.trigger("ready");
        _idletimer.start();
    })


   };

    return (
        
        <div className="video-holder">


    
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />

         </div>
        
    );
};



export default Player;