import React from 'react';

import { renderFormattedText } from "../../../utils/helpers";

import './QuestionSummary.css'

const QuestionSummary = (props) => {

    return (
        <>
            {renderFormattedText(props.content.summarytext)}
        </>
    );
};

export default QuestionSummary;