import React, { useContext } from 'react';

const moduleContext = React.createContext();
const moduleUpdateContext = React.createContext();

export function useModuleIndex() {
   return useContext(moduleContext);
}

export function useModuleIndexUpdate() {
    return useContext(moduleUpdateContext)
}

export function ModuleProvider({ children }) {

    const [module,setModule] = React.useState(null);
    function setModuleIndex(_module) {
        setModule(_module);
    }
    
    return (

        <moduleContext.Provider value={module}>
            <moduleUpdateContext.Provider value={setModuleIndex}>
                {children}
            </moduleUpdateContext.Provider>
        </moduleContext.Provider>

    )
}
