import React, {useState, useEffect} from 'react';
import { useData } from "../../components/context/DataContext";
import { useScreenShow, useScreenShowUpdate } from "../../components/context/ScreenContext";
import { useModuleIndex, useModuleIndexUpdate } from "../../components/context/ModuleContext";
import { useScreenType, useScreenTypeUpdate } from "../../components/context/MixContext";
import { useScore } from "../../components/context/ProgressContext";
import { motion } from "framer-motion";

import './MenuLink.css'

const MenuLink = (props) => {

    const [score, setScore] = useState(0);

    // data
    const json = useData();

    // Screen show
    const getScreenShow = useScreenShow();
    const setScreenShow = useScreenShowUpdate();

    // module index
    const getModuleIndex = useModuleIndex();
    const setModuleIndex = useModuleIndexUpdate();

    // Score
    const getScore = useScore();

    // Screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();


    const handleClick = () => {
        setModuleIndex(props.indx);
        setScreenShow(false);
        setScreenType("quiz")
    }

    const scoreCalc = () => {
        const scoreTotal = (getScore[props.indx] / json.quiz[props.indx].questions.length-1) * 100;
        return scoreTotal;
    }


    return (

        <motion.div className="link-wrap"
            initial={{ opacity: 0, scale: .5 }}
            animate={{ opacity: 1, scale: 1 }} 
            transition={{ delay: props.delay, duration: .5 }}
        >
            <div className="link-click-wrap" onClick={handleClick}>
                <div className="link-img">
                    <img src={require(`../../assets/images/mod${props.indx}-img.png`)} width="100%" alt="Menu" />
                </div>
                <div className="link-title">
                    {json && json.quiz[props.indx].title} 
                </div>
                <div className="link-click"></div>
            </div>
            
            <div className="link-progress">
                <div className="progress-wrap">
                    <div className="progress" style={{"width" : getScore[props.indx] === 0 ? '0%' : getScore[props.indx] / Number(json.quiz[props.indx].questions.length-1) * 100 + "%"}}></div>
                </div>
                {/* You need to set the inital value of the score array in progressContext.js */}
                <div className="progress-txt">{getScore[props.indx] === 0 ? '0' : Math.round(getScore[props.indx] / Number(json.quiz[props.indx].questions.length-1) * 100)}% CORRECT</div>
            </div>
        </motion.div>

    );
};

export default MenuLink;