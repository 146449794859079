import React, { useContext } from 'react';

const screenContext = React.createContext();
const screenUpdateContext = React.createContext();

export function useScreenShow() {
   return useContext(screenContext);
}

export function useScreenShowUpdate() {
    return useContext(screenUpdateContext)
}

export function ScreenProvider({ children }) {

    const [screen,setScreen] = React.useState(null);
    function setScreenData(_screen) {
        setScreen(_screen);
    }
    
    return (

        <screenContext.Provider value={screen}>
            <screenUpdateContext.Provider value={setScreenData}>
                {children}
            </screenUpdateContext.Provider>
        </screenContext.Provider>

    )
}
