import React, { useContext } from 'react';

const localeContext = React.createContext();
const localeUpdateContext = React.createContext();

export function useLocale() {
   return useContext(localeContext);
}

export function useLocaleUpdate() {
    return useContext(localeUpdateContext)
}

export function LocaleProvider({ children }) {

    const [locale,setLocale] = React.useState(null);
    function setAppLocale(_locale) {
        setLocale(_locale);
    }
    
    return (

        <localeContext.Provider value={locale}>
            <localeUpdateContext.Provider value={setAppLocale}>
                {children}
            </localeUpdateContext.Provider>
        </localeContext.Provider>

    )
}
